import React from 'react';
import ModelCanvas from '../components/ModelCanvas';
import Layout from '../components/Layout';
import Button from '../components/Button';

export default function indexPage() {
  const isBrowser = typeof window !== 'undefined';

  return (
    <Layout fullMenu>
      <article id="main">
        <header>
          <h2>Coronary Artery Bypass</h2>
        </header>
        <section className="wrapper style5">
          <div className="inner model">
            <section>
              <div className="row">
                <div className="col-6 col-12-medium">
                  <h2>Coronary Artery Bypass Grafting (CABG)</h2>
                  <p>
                    is a surgical procedure to restore normal blood flow to an
                    obstructed coronary artery. CABG is often indicated when
                    coronary arteries have a 50 to 99 percent obstruction. It is
                    used for patients who have severe coronary artery disease.
                  </p>
                  <p>
                    About 3D model: This model represents heart after CABG
                    surgery with corrected stenosis on RIA (ramus
                    interventricularis anterior) with LIMA (left internal
                    mammary artery). During the procedure, LIMA is harvested
                    from the chest wall and its distal end is sutured below the
                    narrowed part of RIA to restore the blood flow. The proximal
                    part of LIMA remains uninterrupted.
                  </p>
                </div>
                {isBrowser && (
                  <div className="col-6 col-12-medium">
                    <ModelCanvas
                      model_path={'/RIA-bypass.glb'}
                      camera_position={[5, 5, 5]}
                      light_intensity={0.4}
                      group_rotation={[0.1, -0.1, 0.0]}
                      group_position={[0.0, -0.4, -1.6]}
                      group_scale={[10, 10, 10]}
                    />
                  </div>
                )}
              </div>
            </section>
            <div>
              <br />
              <ul className="actions stacked">
                <li>
                  <br />
                  <Button
                    buttonClass="button small primary"
                    url="https://biovoxel.tech/en/services/organ-models/"
                    text="Print 3D Model"
                    action="print"
                  />{' '}
                  <Button
                    buttonClass="button icon solid fa-coffee"
                    url="https://buymeacoff.ee/3DHearter"
                    text="^^"
                    action="donate"
                  />
                </li>
              </ul>
            </div>
          </div>
        </section>
      </article>
    </Layout>
  );
}
